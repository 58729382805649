import React, { Component } from "react";
import _ from "lodash";
import { List, Header, Label } from "semantic-ui-react";
import { Button } from "config/initializers/styles.app";
import { hashHistory } from "react-router";
import FlexBox from "globals/flexbox/FlexBox.react";

export class MenuList extends Component {
    goToCourse = (id) => {
        hashHistory.push(`/courses/${id}`);
    };

    handleSectionSelection = (filter) => {
        if (filter === "Completed") {
            hashHistory.push(`/articles?category=library&filter=completed`)
        } else if (filter === "Started") {
            hashHistory.push(`/articles?category=library&filter=started`)
        } else {
            hashHistory.push(`/articles?category=library&filter=notstarted`)
        }
    }

    render() {
        let listItem = {
            lineHeight: "1.5",
            cursor: "pointer",
        };
        let tagStyle = {
            cursor: "pointer",
            marginTop: "3%"
        };
        switch (this.props.content.items) {
            case "articles":
                let allArticles =
                    this.props.content.category === ""
                        ? this.props.articles
                        : _.filter(this.props.articles, {
                            category: this.props.content.category,
                        });
                return (
                    <List divided relaxed>
                        {_.map(
                            allArticles.slice(0, this.props.content.count),
                            (article) => {
                                return (
                                    <List.Item key={Math.random()}>
                                        <List.Content style={listItem}>
                                            {article.name}
                                            <br />
                                            <a
                                                onClick={() =>
                                                    this.props.goToPage(
                                                        "/articles/show/" +
                                                        article._id
                                                    )
                                                }
                                            >
                                                Learn More...
                                            </a>
                                        </List.Content>
                                    </List.Item>
                                );
                            }
                        )}
                        {this.props.content.seemore && (
                            <List.Item>
                                <br />
                                <Button
                                    content="See More"
                                    onClick={() =>
                                        this.props.goToPage(
                                            this.props.content.seemoreLink
                                        )
                                    }
                                />
                            </List.Item>
                        )}
                    </List>
                );
            case "courses":
                let allPrograms = _.reverse(_.uniqBy(
                    _.map(_.values(this.props.courses), (c) => {
                        return this.props.programs[c.parent];
                    }),
                    "_id"
                ));

                let priortizedPrograms = _.sortBy(allPrograms, ['priority']).reverse();

                return (
                    <div>
                        <Label key={Math.random()} color="green" style={tagStyle} onClick={() => this.handleSectionSelection("Completed")}>
                            Completed
                        </Label>
                        <Label key={Math.random()} color="yellow" style={tagStyle} onClick={() => this.handleSectionSelection("Started")}>
                            Started
                        </Label>
                        <Label key={Math.random()} color="grey" style={tagStyle} onClick={() => this.handleSectionSelection("NotStarted")}>
                            Not Started
                        </Label>
                        <List divided relaxed>
                            <List.Item
                                key={Math.random()}
                                onClick={this.props.clearTags}
                            >
                                <List.Content style={listItem}>All</List.Content>
                            </List.Item>
                            {_.map(priortizedPrograms, (program) => {
                                return (
                                    <List.Item
                                        key={Math.random()}
                                        onClick={() =>
                                            this.props.selectTag(program)
                                        }
                                    >
                                        <List.Content style={listItem}>
                                            {program ? program.name : ""}
                                        </List.Content>
                                    </List.Item>
                                );
                            })}
                        </List>
                    </div>
                );
            case "courseList":
                let filteredCourses = this.props.content.recent ?
                    _.reverse(_.values(this.props.courses)).slice(
                        0,
                        this.props.content.count
                    )
                    : _.values(this.props.courses).slice(
                        0,
                        this.props.content.count
                    )

                return (
                    <div>
                        {filteredCourses.length > 0 && (
                            <List divided relaxed>
                                {_.map(filteredCourses,
                                    (course) => {
                                        return (
                                            <List.Item
                                                key={Math.random()}
                                                onClick={() =>
                                                    this.goToCourse(course._id)
                                                }
                                            >
                                                <List.Content style={listItem}>
                                                    {course.name}
                                                </List.Content>
                                            </List.Item>
                                        );
                                    }
                                )}
                                {this.props.content.seemore && (
                                    <List.Item>
                                        <br />
                                        <Button
                                            content="See More"
                                            onClick={() =>
                                                this.props.goToPage(
                                                    this.props.content.seemoreLink
                                                )
                                            }
                                        />
                                    </List.Item>
                                )}
                            </List>
                        )}
                        {filteredCourses.length === 0 && (
                            <FlexBox>
                                <Header as="h4" icon textAlign="center">
                                    <Header.Content>
                                        You are not enrolled into any courses yet.
                                    </Header.Content>
                                </Header>
                            </FlexBox>
                        )}
                    </div>
                );
            default:
                return null;
        }
    }
}
