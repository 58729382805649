// Import from NPM
// -------------------------------------
import React from "react";
import _ from "lodash";
import { connect } from "react-redux";
import "semantic-ui-css/semantic.min.css";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { Modal, Grid } from "semantic-ui-react";


// Import Actions and Helpers
// -------------------------------------
import AnalyticsAction from "redux/Analytics.action.react.js";


// Import from Config
// -------------------------------------

// Import Components
// -------------------------------------
import LearnerAnalyticsWrapper from "./Wrapper/LearnerAnalyticsWrapper.react";
import ListingMenu from "../blog/components/ListingMenu.react";
import { getAppConfig } from "config/client.config";
import { structure } from "config/settings/learning_center.settings";
import NotificationAction from "appRedux/Notification.action";
import TrackerAction from "backend/tracker/redux/Tracker.action.react";

/**
 * The AnalyticsContainer is the top level component connected to the redux store.
 */
export class AnalyticsContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            sent: false,
            failed: false,
        };
    }
    mailReportPage = (name) => {
        this.setState({ syncing: true }, () => {
            let reportPageView = document.querySelector(".reportPage");
            html2canvas(reportPageView, { useCORS: true }).then((canvas) => {
                let imgData = canvas.toDataURL("image/png");
                var pdf = new jsPDF("p", "mm", "a4", true);
                var width = pdf.internal.pageSize.getWidth();
                var height = pdf.internal.pageSize.getHeight();
                pdf.addImage(imgData, "PNG", 0, 0, width, height, "", "SLOW");
                let sendPDF = pdf.output("datauristring", name + ".pdf");
                this.props.actions
                    .mailReportPage(sendPDF)
                    .then(() => this.setState({ syncing: false, sent: true }))
                    .catch(() =>
                        this.setState({ syncing: false, failed: true })
                    );
            });
        });
    };
    downloadReportPage = (name) => {
        this.setState({ syncing: true }, () => {
            let reportPageView = document.querySelector(".reportPage");
            html2canvas(reportPageView, { useCORS: true }).then((canvas) => {
                let imgData = canvas.toDataURL("image/png");
                var pdf = new jsPDF("p", "mm", "a4", true);
                var width = pdf.internal.pageSize.getWidth();
                var height = pdf.internal.pageSize.getHeight();
                pdf.addImage(imgData, "PNG", 0, 0, width, height, "", "SLOW");
                pdf.save((name + ".pdf").replace(/ /g, "_").toLowerCase());
                this.setState({ syncing: false });
            });
        });
    };

    nameToHex = (name) => {
        let hash = 0;
        for (let i = 0; i < name.length; i++) {
            hash = name.charCodeAt(i) + ((hash << 5) - hash);
        }
        let hexColor = "#";
        for (let i = 0; i < 3; i++) {
            let value = (hash >> (i * 8)) & 0xff;
            hexColor += ("00" + value.toString(16)).substr(-2);
        }
        return hexColor;
    };

    fixColor = (name) => {
        let hexColor = this.nameToHex(name);
        let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hexColor);
        let color = {
            r: parseInt(result[1], 16),
            g: parseInt(result[2], 16),
            b: parseInt(result[3], 16),
        };
        if (color.r * 0.299 + color.g * 0.587 + color.b * 0.114 > 186) {
            var R = parseInt(hexColor.substring(1, 3), 16);
            var G = parseInt(hexColor.substring(3, 5), 16);
            var B = parseInt(hexColor.substring(5, 7), 16);

            R = parseInt((R * 60) / 100, 0);
            G = parseInt((G * 60) / 100, 0);
            B = parseInt((B * 60) / 100, 0);

            R = R < 255 ? R : 255;
            G = G < 255 ? G : 255;
            B = B < 255 ? B : 255;

            var RR =
                R.toString(16).length === 1
                    ? "0" + R.toString(16)
                    : R.toString(16);
            var GG =
                G.toString(16).length === 1
                    ? "0" + G.toString(16)
                    : G.toString(16);
            var BB =
                B.toString(16).length === 1
                    ? "0" + B.toString(16)
                    : B.toString(16);

            return "#" + RR + GG + BB;
        } else {
            return hexColor;
        }
    };

    getAvatar = (name, size) => {
        return (
            <svg height={size} width={size}>
                <rect
                    fill={this.fixColor(name)}
                    x="0"
                    y="0"
                    height={size}
                    width={size}
                />
                <text
                    fill={"#ffffff"}
                    fontSize={size / 2}
                    textAnchor="middle"
                    x={size * 0.5}
                    y={size * 0.67}
                >
                    {_.upperCase(name.split(" ")[0][0])}
                    {_.upperCase(
                        name.split(" ").length === 1
                            ? _.upperCase(name.split(" ")[0][1])
                            : name.split(" ")[1][0]
                    )}
                </text>
            </svg>
        );
    };

    render() {
        let isPortrait = window.innerHeight > window.innerWidth;
        let analyticsWrapper = () => {
            return (
                <LearnerAnalyticsWrapper
                    {...this.props}
                    downloadReportPage={this.downloadReportPage}
                    mailReportPage={this.mailReportPage}
                    getAvatar={this.getAvatar}
                    nameToHex={this.nameToHex}
                    syncing={this.state.syncing}
                    user={this.props.auth.user}
                />
            )
        }

        return (
            <div>
                {isPortrait ?
                    <div>
                        {analyticsWrapper()}
                    </div>
                    :
                    <div>
                        {this.props.slideMenuVisible ?
                            <div className="full-height">
                                <Modal
                                    open={this.props.slideMenuVisible}
                                    onClose={this.props.handleClose}
                                    closeIcon
                                >
                                    <Modal.Content>
                                        {analyticsWrapper()}
                                    </Modal.Content>
                                </Modal>
                            </div>
                            :
                            <Grid style={{ marginTop: "60px" }}>
                                <Grid.Row>
                                    <Grid.Column width={4}>
                                        <ListingMenu
                                            {...this.props}
                                            structure={_.assign(
                                                {},
                                                structure.reports,
                                                getAppConfig().learningCenter.reports
                                            )}
                                            fetchNotifications={this.props.actions.fetchNotifications}
                                            fetchLearnerProgress={this.props.actions.fetchLearnerProgress}
                                            getAppLeaderboard={this.props.actions.getAppLeaderboard}
                                        />
                                    </Grid.Column>
                                    <Grid.Column width={12}>
                                        {analyticsWrapper()}
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        }
                    </div>
                }
            </div>
        );
    }
}

const mapStateToProps = /* istanbul ignore next - redux function */ (state) => {
    return {
        auth: state.auth,
        notification: state.notification
    };
};

const mapDispatchToProps = /* istanbul ignore next - redux function */ (
    dispatch
) => {
    return {
        actions: {
            mailReportPage: (file) => {
                return dispatch(AnalyticsAction.mailReportPage(file));
            },
            fetchUserDetails: (learnerId) => {
                return dispatch(AnalyticsAction.fetchUserDetails(learnerId));
            },
            fetchLearnerProfile: (learnerId) => {
                return dispatch(AnalyticsAction.fetchLearnerProfile(learnerId));
            },
            fetchLearnerLog: (learnerId) => {
                return dispatch(AnalyticsAction.fetchLearnerLog(learnerId));
            },
            fetchLearnerActivity: (learnerId) => {
                return dispatch(
                    AnalyticsAction.fetchLearnerActivity(learnerId)
                );
            },
            fetchLearnerCourses: (learnerId) => {
                return dispatch(AnalyticsAction.fetchLearnerCourses(learnerId));
            },
            fetchLearnerProgress: (learnerId) => {
                return dispatch(
                    AnalyticsAction.fetchLearnerProgress(learnerId)
                );
            },
            fetchLearnerCourseProgress: (learnerId, courseId) => {
                return dispatch(
                    AnalyticsAction.fetchLearnerCourseProgress(
                        learnerId,
                        courseId
                    )
                );
            },
            fetchLearnerCompletedCourses: (learnerId) => {
                return dispatch(
                    AnalyticsAction.fetchLearnerCompletedCourses(learnerId)
                );
            },
            fetchLearnerPerformance: (learnerId) => {
                return dispatch(
                    AnalyticsAction.fetchLearnerPerformance(learnerId)
                );
            },
            fetchLearnerCoursePerformance: (learnerId, courseId) => {
                return dispatch(
                    AnalyticsAction.fetchLearnerCoursePerformance(
                        learnerId,
                        courseId
                    )
                );
            },
            fetchNotifications: (learnerId, count) => {
                return dispatch(NotificationAction.fetchNotifications(learnerId, count));
            },
            dispatchPerformanceAccess: () => {
                return dispatch(
                    AnalyticsAction.dispatchPerformanceAccess()
                );
            },
            pushTrackerToServer: () => {
                return dispatch(TrackerAction.pushTrackerToServer());
            },
            getAppLeaderboard: () => {
                return dispatch(AnalyticsAction.getAppLeaderboard());
            }
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AnalyticsContainer);
