import React, { Component } from 'react'
import _ from "lodash";
import Truncate from "react-truncate";
import { Loader, Segment, Dimmer, Icon, Modal } from "semantic-ui-react";
import { getAppConfig } from "config/client.config";

export default class GamifiedLeaderboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            leaderboard: [],
            error: false,
            hovered: false,
            me: {},
            noContent: false,
            selectedRank: null,
            fetching: false
        };
    }

    componentDidMount() {
        this.setState({
            fetching: true
        });
        this.props
            .getAppLeaderboard()
            .then((data) => {
                if (data && data.length > 0) {
                    let sortedData =
                        _.filter(data, (d) => {
                            return d.points
                        });
                    let currentUser = _.find(sortedData, {
                        _id: this.props.auth.user.id,
                    });
                    let currentUserRank = sortedData.indexOf(currentUser) + 1;
                    this.setState({
                        leaderboard: sortedData,
                        me: {
                            id: currentUser ? currentUser.id
                                : this.props.auth.user.userId,
                            rank: currentUserRank ? currentUserRank : "N/A",
                            first_name: currentUser
                                ? currentUser.first_name
                                : this.props.auth.user.first_name,
                            last_name: currentUser
                                ? currentUser.last_name
                                : this.props.auth.user.last_name,
                            points: currentUser ? currentUser.points : 0,
                        },
                        fetching: false
                    });
                } else {
                    this.setState({
                        noContent: true,
                        fetching: false
                    });
                }
            })
            .catch(
            /* istanbul ignore next - tested in the render */ e => {
                    console.log("e : ", e)
                    this.setState({
                        error: true,
                        fetching: false
                    });
                }
            );
    }

    handleHover = () => {
        let hover = !this.state.hovered;
        this.setState({
            hovered: hover
        });
    }

    closeModal = () => {
        this.setState({
            usersCountList: [], overlay: false
        })
    }

    handleUsersCountClick = (usersCount, index) => {
        this.setState({
            usersCountList: usersCount, overlay: true, selectedRank: index
        })
    }

    render() {
        let pointsGroup, keys;
        let leaderBoardRow = {
            display: "flex",
            width: "100%",
            maxHeight: "6vh",
            height: "4vh",
            margin: "1% 0%",
            borderRadius: "5px",
            fontWeight: "bold",
            fontSize: "0.8em",
            alignItems: 'center'
        }, usersCountLeaderBoard = {
            ...leaderBoardRow,
            margin: "2% 0%",
            cursor: "pointer",
        }, leaderBoardRowBottom = {
            display: "flex",
            borderRadius: "5px",
            fontWeight: "bold",
            fontSize: "0.8em",
            background: getAppConfig().colors.accents.primary,
            color: getAppConfig().colors.accents.primaryText,
        }, leaderBoardRowBottomHov = {
            ...leaderBoardRowBottom,
            fontSize: "0.85em"
        }, medalStyle = {
            maxHeight: "3vh",
            width: "30px",
        }, leaderBoardMedalStyle = {
            maxHeight: "4vh",
            margin: "auto",
            width: "25%",
        }, currentUserMedalStyle = {
            ...leaderBoardMedalStyle,
            width: "30px",
        }, nameStyle = {
            alignSelf: "center",
            flex: '1'
        }, rankStyle = {
            marginLeft: "2px",
            alignSelf: "center",
            width: "18%",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            textAlign: 'center',
        }, scoreStyle = {
            alignSelf: "center",
            width: "20%",
            textAlign: 'center'
        }, rowStyle = {
            ...leaderBoardRow,
            background: getAppConfig().colors.accents.primaryText,
            color: getAppConfig().colors.accents.primary
        }, rowStyleHeader = {
            color: getAppConfig().colors.accents.secondary
        };

        if (this.state.leaderboard) {
            pointsGroup = groupByJsonKey(this.state.leaderboard, "points");
            keys = _.reverse(Object.keys(pointsGroup));
        }

        if (this.props.content.count) {
            pointsGroup = Array.prototype.slice.call(pointsGroup, parseInt(this.props.content.count, 2))
        }

        const CurrentUser = () => {
            let rankImage =
                this.state.me.rank === "N/A" ?
                    "assets/images/defaults/leaderboard_newplayer.png"
                    : this.state.me.rank === 1
                        ? "assets/images/defaults/leaderboard_rank1.png"
                        : this.state.me.rank === 2
                            ? "assets/images/defaults/leaderboard_rank2.png"
                            : this.state.me.rank === 3
                                ? "assets/images/defaults/leaderboard_rank3.png"
                                : "assets/images/defaults/leaderboard_norank.png";
            return (
                <div
                    key={"leader-me"}
                    style={this.state.hovered ? leaderBoardRowBottomHov : leaderBoardRowBottom}
                >
                    <img alt={"medal"} src={rankImage} style={currentUserMedalStyle} />
                    <span style={rankStyle}>
                        {this.state.hovered ?
                            <div>
                                {this.state.me.rank}
                            </div> :
                            <Truncate ellipsis="...">
                                {this.state.me.rank}
                            </Truncate>}
                    </span>
                    <div style={nameStyle}>
                        {this.state.hovered ?
                            <div>
                                {this.state.me.first_name}{" "}
                                {this.state.me.last_name}
                            </div> :
                            <Truncate ellipsis="...">
                                {this.state.me.first_name}{" "}
                                {this.state.me.last_name}
                            </Truncate>}
                    </div>
                    <div style={scoreStyle}>
                        {this.state.me.points}
                    </div>
                </div>
            )
        }

        const SingleUser = ({ usersCount, rankImage }) => {
            return (
                <div style={rowStyle}>
                    <div style={{ display: "flex" }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <img
                                alt={"medal"}
                                src={rankImage}
                                style={leaderBoardMedalStyle}
                            />
                            <div style={{ width: "100px" }}>
                                <Truncate ellipsis="...">
                                    {usersCount[0].first_name}{" "}
                                    {usersCount[0].last_name}
                                </Truncate>
                            </div>
                        </div>
                        <div style={{ width: "100px", display: 'flex', alignItems: 'center' }}>
                            <Truncate ellipsis="...">
                                {`Points: ${usersCount[0].points}`}
                            </Truncate>
                        </div>
                    </div>
                </div>
            )
        }

        const RankGroup = () => {
            return (
                <Modal
                    open={this.state.overlay}
                    closeIcon
                    onClose={this.closeModal}
                    size="mini"
                >
                    <Modal.Content style={{ display: "flex", flexDirection: "column", height: "auto" }}>
                        <div
                            key={"leader-"}
                            style={{ ...rowStyle, ...rowStyleHeader }}
                        >
                            <div style={medalStyle}></div>
                            <div style={nameStyle}>
                                <h4>Name</h4>
                            </div>
                            <div style={scoreStyle}>
                                <h4>Points</h4>
                            </div>
                        </div>
                        {_.map(
                            this.state.usersCountList.slice(0, 10),
                            (user, index) => {
                                let rankImage =
                                    this.state.selectedRank === 0
                                        ? "assets/images/defaults/leaderboard_rank1.png"
                                        : this.state.selectedRank === 1
                                            ? "assets/images/defaults/leaderboard_rank2.png"
                                            : this.state.selectedRank === 2
                                                ? "assets/images/defaults/leaderboard_rank3.png"
                                                : "assets/images/defaults/leaderboard_norank.png";
                                return (
                                    <div
                                        key={"leader-" + index}
                                        style={rowStyle}
                                    >
                                        <img alt={"medal"} src={rankImage} style={medalStyle} />
                                        <div style={nameStyle}>
                                            <Truncate ellipsis="...">
                                                {user.first_name}{" "}
                                                {user.last_name}
                                            </Truncate>
                                        </div>
                                        <div style={scoreStyle}>
                                            <Truncate ellipsis="...">
                                                {user.points}
                                            </Truncate>
                                        </div>
                                    </div>
                                );
                            }
                        )}
                        <div>
                            {(this.state.me) && (
                                <CurrentUser />
                            )}
                        </div>
                    </Modal.Content>
                </Modal>
            )
        }

        if (this.state.fetching) {
            return (
                <Segment style={{ height: "150px" }}>
                    <Dimmer active inverted>
                        <Loader inverted content="Loading" />
                    </Dimmer>
                </Segment>
            );
        } else if (this.state.noContent || this.state.error) {
            return (
                <div>
                    <Segment placeholder style={{ textAlign: "center" }}>
                        <Icon.Group size="huge">
                            <Icon size="big" color="red" name="dont" />
                            <Icon color="black" name="trophy" />
                        </Icon.Group>
                        <h3>No Data Found</h3>
                    </Segment>
                </div>
            );
        } else {
            return (
                <div style={{ height: '100%' }}>
                    <div style={{
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        paddingBottom: '2.5em'
                    }}>
                        <div style={{ marginTop: "12%" }}>
                            <div>
                                {this.state.overlay && RankGroup()}
                            </div>
                            {_.reverse(_.map(pointsGroup,
                                (usersCount, index) => {
                                    let tempIndex = keys.indexOf(index);
                                    let rankImage =
                                        tempIndex === 0
                                            ? "assets/images/defaults/leaderboard_rank1.png"
                                            : tempIndex === 1
                                                ? "assets/images/defaults/leaderboard_rank2.png"
                                                : tempIndex === 2
                                                    ? "assets/images/defaults/leaderboard_rank3.png"
                                                    : "assets/images/defaults/leaderboard_norank.png";
                                    let rowStyle = {
                                        ...usersCountLeaderBoard,
                                        background: getAppConfig().colors.accents.primaryText,
                                        color: getAppConfig().colors.accents.primary
                                    }
                                    return (
                                        usersCount.length > 1 ?
                                            <div
                                                key={"usersCount leaderboard-" + index}
                                                style={rowStyle}
                                                onClick={() => this.handleUsersCountClick(usersCount, tempIndex)}
                                            >
                                                <div style={{ display: "flex" }}>
                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                        <img
                                                            alt={"medal"}
                                                            src={rankImage}
                                                            style={leaderBoardMedalStyle}
                                                        />
                                                        <div style={{ width: "100px" }}>
                                                            <Truncate ellipsis="...">
                                                                {`Users : ${usersCount.length}`}
                                                            </Truncate>
                                                        </div>
                                                    </div>
                                                    <div style={{ width: "100px", display: 'flex', alignItems: 'center' }}>
                                                        <Truncate ellipsis="...">
                                                            {`Points: ${usersCount[0].points}`}
                                                        </Truncate>
                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            <SingleUser key={index} usersCount={usersCount} rankImage={rankImage} />
                                    );
                                }
                            ))
                            }
                        </div>
                        <div style={this.state.hovered ? { transform: "scale(1.05)" } : {}} onMouseEnter={this.handleHover} onMouseLeave={this.handleHover} >
                            {(this.state.me) && (
                                <CurrentUser />
                            )}
                        </div>
                    </div>
                </div>
            );
        }
    }
}

const groupByJsonKey = (xs, key) => {
    return xs.reduce(function (arr, point) {
        (arr[point[key]] = arr[point[key]] || []).push(point);
        return arr;
    }, {});
}
