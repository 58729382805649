// Import from NPM
// -------------------------------------
import React from "react";
import _ from "lodash";
import { Input } from "semantic-ui-react";
import { hashHistory } from "react-router";
import MiniSearch from "minisearch";

// Import from Config
// -------------------------------------

// Import Components
// -------------------------------------

const stopWords = new Set(['the', 'a', 'an', 'and'])

export class SearchBar extends React.Component {
    constructor(props) {
        super(props);
        const miniSearch = new MiniSearch({
            fields: ["name", "tags"],
            searchOptions: {
                boost: { name: 2 },
                fuzzy: 0.2,
                processTerm: (term, _fieldName) => (term.length <= 1 || stopWords.has(term)) ? null : term.toLowerCase()
            }
        });

        this.state = {
            searchText: "",
            suggestions: [],
            selectedSuggestion: -1,
            miniSearch
        };
    }

    componentDidMount() {
        const { miniSearch } = this.state;
        let source = this.props.blog && this.props.blog.articles ? this.props.blog.articles : [];

        if (source.length > 0) {
            let allArticles = _.map(source, (article) => {
                return {
                    id: article._id,
                    name: article.name,
                    tags: _.map(article.tags, "name").join(", "),
                };
            });
            miniSearch.addAll(allArticles)
        }
    }

    handleSearch = (e) => {
        if (e.key === "Enter") {
            if (this.state.searchText.length > 2)
                hashHistory.push(`/articles?search=${this.state.searchText}`);
            this.setState({
                searchText: "",
                suggestions: [],
                selectedSuggestion: -1
            });
        } else {
            this.setState({ searchText: e.target.value });
        }
    };

    handleSuggestionClick(i) {
        let { suggestions } = this.state
        const searchValue = suggestions[i].suggestion;
        this.setState({
            searchText: "",
            suggestions: [],
            selectedSuggestion: -1
        });
        hashHistory.push(`/articles?search=${searchValue}`);
    }

    handleSearchChange = ({ target: { value } }) => {
        this.setState({ searchText: value })
        const selectedSuggestion = -1
        const suggestions = this.getSuggestions(value);
        this.setState({ suggestions, selectedSuggestion })
    }

    getSuggestions = (query) => {
        const { miniSearch } = this.state;
        return miniSearch.autoSuggest(query, { fuzzy: 0.2 });
    }

    render() {

        let suggestionsList = {
            listStyle: "none",
            padding: "0",
            border: "1px solid #ccc",
            borderTop: "0",
            margin: "0 0 0.2em 0",
            borderRadius: "5px",
            background: "#ffffffed",
            position: "absolute",
            zIndex: "20",
            left: "13px",
            right: "13px",
        }

        let suggestion = {
            padding: "0.5em 1em",
            borderBottom: "1px solid #eee"
        }

        const SuggestionList = ({ items, selectedSuggestion }) => {
            return (
                <ul style={suggestionsList}>
                    {
                        items.map(({ suggestion }, i) =>
                            <Suggestion value={suggestion} selected={selectedSuggestion === i}
                                onClick={(event) => this.handleSuggestionClick(i, event)} key={i} />)
                    }
                </ul>
            )
        }

        const Suggestion = ({ value, selected, onClick }) => (
            <li style={suggestion} onClick={onClick}>{value}</li>
        )

        return (
            <div>
                {this.props.blog.articles.length >
                    0 && (
                        <div>
                            <Input
                                fluid
                                icon="search"
                                placeholder="Search..."
                                onChange={this.handleSearchChange}
                                onKeyUp={this.handleSearch}
                                onKeyDown={this.handleSearchKeyDown}
                                value={this.state.searchText}
                                style={{ marginTop: "9px" }}
                            />
                            {this.state.suggestions && this.state.suggestions.length > 0 &&
                                <SuggestionList
                                    items={this.state.suggestions}
                                    selectedSuggestion={this.state.selectedSuggestion}
                                />
                            }
                        </div >
                    )}
            </div >
        );
    }
}
