import React from "react";
import SlideHeader from "../../contentBlocks/SlideHeader.react";
import SlideHeaderSidebar from "../../contentBlocks/SlideHeaderSidebar.react";
import PropTypes from "prop-types";
import { Grid, Checkbox } from "semantic-ui-react";
import _ from "lodash";

// Import from Config
// -------------------------------------
import { getAppConfig } from "config/client.config";
import { Button } from "config/initializers/styles.app";

export default class WithoutDateCheckList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedOptions: [],
        }
    }
    static propTypes = {
        data: PropTypes.shape({
            title: PropTypes.string,
            subtitle: PropTypes.string,
            iconlist: PropTypes.array,
        }).isRequired,
        colors: PropTypes.shape({
            baseText: PropTypes.string,
        }).isRequired,
    };

    componentDidMount() {
        this.props.getSlideResponse(this.props.deckId, this.props.slideSeq).then(res => {
            if (res.length > 0 && res[0].response) {
                this.setState({
                    selectedOptions: res[0].response
                })
            }
        })

    }

    handleOptionClick = (index) => {
        let list = this.state.selectedOptions;
        var idx = _.indexOf(list, index);
        if (idx !== -1) {
            list.splice(idx, 1);
        } else {
            list.push(index);
        }
        this.setState({ selectedOptions: list });
    };

    handleOnSubmit = () => {
        this.props.trackInteraction(this.state.selectedOptions)
        let list = [];
        this.setState({ selectedOptions: list });
    };

    render() {
        let isPortrait =
            this.props.mobileView || window.innerHeight > window.innerWidth;
        let defaultStyle = {
            margin: "auto",
            width: isPortrait ? "80%" : "90%",
            textAlign: "left",
            padding: isPortrait ? "40px 0" : "15px",
        };
        let checkDivStyle = {
            width: "100%",
            margin: "0px auto 5px",
            minHeight: "50px",
            borderRadius: "5px",
            border: getAppConfig().ui.invertedButtons ? `2.5px solid ${getAppConfig().colors.accents.primary}` : "",
            background: !getAppConfig().ui.invertedButtons ? getAppConfig().colors.accents.primary : "",
            display: "flex",
            flexDirection: "row",
            alignContent: "center",
            justifyContent: "space-between",
            alignItems: "center",
            fontWeight: "bold"
        };
        let btnStyle = {
            margin: "auto"
        };
        
        return (
            <div className="full-height" >
                {
                    isPortrait ? (
                        <div>
                            <SlideHeader
                                {...this.props}
                                title={this.props.data.title}
                                subtitle={this.props.data.subtitle}
                            />
                            <div style={defaultStyle}>
                                {this.props.data.without_date_check_list_default
                                    .map((item, idx) => (
                                        <div key={`without-date-check-list-${idx}`} style={checkDivStyle}>
                                            <div style={{
                                                float: 'right',
                                                marginLeft: '5px'
                                            }}>
                                                <Checkbox
                                                    label={<label>{item.text}</label>}
                                                    onChange={() => this.handleOptionClick(idx)}
                                                    checked={this.state.selectedOptions.includes(idx) ? true : false} />
                                            </div>
                                        </div>
                                    ))}
                                <Button
                                    content="Submit"
                                    onClick={this.handleOnSubmit}
                                    style={btnStyle}
                                />
                            </div>
                        </div>
                    ) : (
                        <Grid style={{ minHeight: "100%" }}>
                            <Grid.Row style={{ paddingBottom: "0" }}>
                                <Grid.Column
                                    width={8}
                                    style={{
                                        paddingRight: "0",
                                        background: this.props.colors.primary,
                                    }}
                                >
                                    <SlideHeaderSidebar
                                        {...this.props}
                                        title={this.props.data.title}
                                        subtitle={this.props.data.subtitle}
                                    />
                                </Grid.Column>
                                <Grid.Column width={8} style={{ paddingLeft: "0" }}>
                                    <div style={defaultStyle}>
                                        {this.props.data.without_date_check_list_default
                                            .map((item, idx) => (
                                                <div key={`without-date-check-list-${idx}`} style={checkDivStyle}>
                                                    <div style={{
                                                        float: 'right',
                                                        marginLeft: '5px'
                                                    }}>
                                                        <Checkbox
                                                            label={<label>{item.text}</label>}
                                                            onChange={() => this.handleOptionClick(idx)}
                                                            checked={this.state.selectedOptions.includes(idx) ? true : false}
                                                        />
                                                    </div>
                                                </div>
                                            ))}
                                        <Button
                                            content="Submit"
                                            onClick={this.handleOnSubmit}
                                            style={btnStyle}
                                        />
                                    </div>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    )}
            </div>
        );
    }
}
